<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title class="display-2">Geo-data collection</v-card-title>
        <v-card-text>   
          <p>
            You can enable or block collection of geo-data for your profile. 
          </p>
          <p>
            Please note that some features of our app will no longer work when geo-data collection is blocked.
          </p>
          <p>
            Please note that changing this setting will not retrospectively change what data we store for your profile. This setting will only impact geo-data collection going forward.
          </p>
        </v-card-text>
        
        <v-card-text v-if="profile">
          <p>
          <v-switch v-model="profile.block_geo_data" label="Block collection or processing of geo-location data" prepend-icon="fa-location" />
          </p>

          <v-btn block color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
        </v-card-text>   

    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Geo-data', disabled: true },
      ],
      profile: null,
      showOk: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {

    async submit() {
      await profileService.put({
        block_geo_data: this.profile.block_geo_data,
      });
      await this.getProfile();      
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
      }
    },
  },
  computed: {
    isValid() {
      return this.profile && this.profile.email;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

